<template>
  <!--  -->
  <div
    ref="container"
    :style="addVisible ? 'overflow:hidden;' : ''"
    class="areaContainer"
  >
    <div class="title">落地类项目</div>
    <div class="tableContainer">
      <div class="addWrap">
        <el-button
          :loading="loading"
          class="add"
          @click.stop="allRead"
          v-if="tableData.length > 0"
          >全部已读</el-button
        >
        <el-button class="add" @click="toEmail">配置邮箱</el-button>
      </div>

      <el-row>
        <el-col class="table">
          <el-table
            :data="tableData"
            border
            header-cell-class-name="headerClass"
            cell-class-name="cellClass"
            style="width: 100%"
            @row-click="rowClick"
          >
            <el-table-column label="ID" width="90">
              <template slot-scope="scope">
                <p class="idWrap">
                  <span
                    :class="{ noRead: true, hasRead: scope.row.readFlag == 1 }"
                  />
                  <span>{{ scope.row.projectId }}</span>
                </p>
              </template>
            </el-table-column>

            <el-table-column
              label="上传时间"
              min-width="214"
              prop="createTime"
            />
            <el-table-column
              label="公司名称"
              min-width="214"
              prop="enterpriseName"
            />
            <el-table-column label="姓名" min-width="156" prop="contactName" />

            <el-table-column
              label="联系电话"
              min-width="154"
              prop="contactMobile"
            />
            <el-table-column label="状态" min-width="142">
              <template slot-scope="scope">
                <span
                  :style="scope.row.readFlag === 1 ? '' : 'cursor:pointer;'"
                  class="editCommon"
                  >{{ scope.row.readFlag === 1 ? "已读" : "未读" }}</span
                >
              </template>
            </el-table-column>
          </el-table>

          <!-- </div> -->
        </el-col>
      </el-row>
      <div class="pagination" style="text-align: center">
        <el-pagination
          :total="projectDetail.total"
          :page-size="10"
          layout="prev, pager, next"
          @current-change="clickPage"
        />
      </div>
    </div>
    <ProjectDetail
      v-if="addVisible"
      ref="project"
      :visible.sync="addVisible"
      :project-id="projectId"
      :is-area="1"
    />
    <Email :visible.sync="emailVisible"></Email>
  </div>
</template>
<script>
import {
  updateAllProjectReadedForZhenJiang,
  getProjectListForZhenJiang,
  updateProjectReadedForZhenJiang,
} from "@/apis/zhenjiang";
import { parseTime } from "@/utils/index";
import ProjectDetail from "./projectDetail";
import Email from "./email";
export default {
  components: {
    ProjectDetail,
    Email,
  },
  data() {
    return {
      tableData: [],
      projectDetail: "",
      projectId: "",
      current: 1,
      addVisible: false,
      emailVisible: false,
      loading: false,
    };
  },
  // mounted() {

  //   window.addEventListener("keydown", this.key);
  //   this.getProjectList(this.current);
  // },
  activated() {
    window.addEventListener("keydown", this.key);
    this.getProjectList(this.current);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.key);
  },
  methods: {
    key(e) {
      if (e.keyCode === 9) {
        // //console.log('tabfalse13')
        e.preventDefault();
      }
    },
    async getProjectList(current = 1) {
      // var res = await this.$store.dispatch('getContactList', { current })
      var res = await getProjectListForZhenJiang({ current, projectType: 1 });
      // //console.log(res)
      var records = res.result.records;
      records.forEach((item) => {
        item.createTime = parseTime(item.createTime);
      });
      records.sort(function (a, b) {
        if (a.readFlag === b.readFlag) {
          return b.projectId - a.projectId;
        }
        return a.readFlag - b.readFlag;
      });
      this.tableData = records;
      this.projectDetail = res.result;
    },
    rowClick(row) {
      // //console.log(row)
      this.addVisible = true;
      this.projectId = row.projectId;
      if (row.readFlag == 1) return;
      updateProjectReadedForZhenJiang({ projectId: row.projectId }).then(() => {
        row.readFlag = 1;
        this.tableData.sort(function (a, b) {
          if (a.readFlag === b.readFlag) {
            return b.projectId - a.projectId;
          }
          return a.readFlag - b.readFlag;
        });
      });
    },
    toEmail() {
      this.emailVisible = true;
    },
    allRead() {
      if (this.loading || this.tableData.length == 0) return;
      this.loading = true;

      // //console.log(111)
      updateAllProjectReadedForZhenJiang({ projectType: 1 })
        .then(() => {
          this.$message1("全部查阅完毕", "success");
          this.getProjectList(this.current);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 页码切换
    clickPage(e) {
      this.getProjectList(e);
      this.current = e;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/mixin";
.areaContainer {
  height: calc(100vh - 118px);
  position: relative;

  .title {
    letter-spacing: 2px;
    font-size: 22px;
    line-height: 1;
    color: #333;
    font-weight: bold;
    padding-left: 34px;
    padding-top: 40px;
  }
}
</style>
